// #bec6d4
.board {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid #344861;
  width: 62%;
  box-sizing: border-box;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  $rows: row0, row1, row2, row3, row4, row5, row6, row7, row8;
  $cols: col0, col1, col2, col3, col4, col5, col6, col7, col8;
  @each $row in $rows {
    .#{$row} {
      display: flex;
      font-size: 35px;
      height: 100%;
      color: #344861;
      @each $col in $cols {
        .#{$col} {
          flex-grow: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          user-select: none;
        }
        .#{$col}:hover {
          background-color: #def;
          cursor: pointer;
        }
        @if $col == col2 or $col == col5 {
          .#{$col} {
            @extend .#{$col};
            border-right: 2px solid #344861;
            border-bottom: 1px solid #bec6d4;
          }
        } @else if $col == col8 {
          .#{$col} {
            border-right: none;
            border-bottom: 1px solid #bec6d4;
          }
        } @else {
          .#{$col} {
            border-right: 1px solid #bec6d4;
            border-bottom: 1px solid #bec6d4;
          }
        }
        @keyframes animate {
          50% {
            background-color: #abc1da;
          }
        }
        @keyframes solved {
          50% {
            background-color: #abc1da;
          }
          100% {
            color: transparent;
          }
        }
      }
    }
    @if $row == row2 or $row == row5 {
      .#{$row} {
        border-bottom: 2px solid #344861;
        @for $index from 0 through 8 {
          .cell#{$index} {
            border-bottom: none;
          }
        }
      }
    }
  }
  .gameover {
    position: absolute;
    background-color: white;
    width: 20%;
    height: 41.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    align-self: center;
    box-shadow: 5px -3px 9px#dbd7d7, -5px 5px 9px #dbd7d7;
    @media screen and (max-width: 1024px) {
      width: 250px;
      height: 250px;
    }
    p {
      padding-top: 0px;
      font-size: 21px;
      @media screen and (max-width: 1024px) {
        font-size: 17px;
      }
    }
  }
  .pause {
    position: absolute;
    background-color: #4a90e2;
    border-radius: 100%;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 4%;
    height: 8.1%;
    @media screen and (max-width: 1024px) {
      width: 60px;
      height: 60px;
    }
    i {
      display: block;
      position: relative;
      cursor: pointer;
      left: 2px;
    }
  }
  .spinner {
    position: absolute;
    border-radius: 100%;
    align-self: center;
    width: 5em;
    height: 5em;
    border: 7px solid #344861;
    border-top-color: transparent;
    animation: spinner 1s linear infinite;
    @media screen and (max-width: 1024px) {
      width: 4rem;
      height: 4rem;
      border-width: 5px;
    }
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(1turn);
    }
  }
  .solved {
    opacity: 0;
    animation: solved 0.5s normal 1s 1 forwards;
    @extend .gameover;
  }
  @keyframes solved {
    100% {
      opacity: 1;
    }
  }
}
