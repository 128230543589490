#about {
  h1 {
    color: #344861;
  }
  #about-header {
    border-bottom: solid;
    border-width: 1px;
    border-bottom-color: #bec6d4;
    #about-wraper {
      display: flex;
      width: 94%;
      margin: auto;
      justify-content: space-between;
      @media screen and (min-width: 768px) {
        width: 70%;
        max-width: 1050px;
      }

      .play-sudoku {
        color: #7c7d7a;
        margin-top: 0.8rem;
        ul {
          list-style: none;
          font-size: 20px;
          font-weight: bold;
          a {
            text-decoration: none;
            color: #7c7d7a;
          }
          li {
            cursor: pointer;
            &:hover {
              #underline {
                animation-name: underline;
                animation-duration: 0.4s;
                animation-fill-mode: forwards;
                height: 2px;
                background-color: #4a90e2;
                margin: 2px auto;
              }
              @keyframes underline {
                0% {
                  width: 0px;
                }
                100% {
                  width: 102%;
                }
              }
            }
          }
        }
      }
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    text-align: center;
    #img {
      width: 90%;
      margin: auto;
      max-width: 800px;
      margin-top: 25px;
    }
    .content {
      width: 95%;
      margin: 50px auto;
      max-width: 1050px;
      @media screen and (min-width: 768px) {
        width: 60%;
      }
      #example-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        @media screen and (min-width: 768px) {
          width: 100%;
          max-width: 600px;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
        }
        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          #sudoku-board {
            width: 100%;
          }
        }
      }
      #sudoku-board {
        width: 100%;
        max-width: 300px;
      }
      h3 {
        color: #344861;
        text-align: left;
        @media screen and (min-width: 768px) {
          font-size: 28px;
        }
      }
      ul {
        text-align: left;
      }
      p,
      li {
        color: #6e7c8c;
        font-size: 17px;
        line-height: 2;
        text-align: justify;
        @media screen and (min-width: 768px) {
          font-size: 20px;
        }
      }
    }
  }
}
