.game-info {
  display: flex;
  margin-top: 15px;
  width: 60%;
  justify-content: space-between;
  align-items: center;
  color: #344861;
  font-size: 20px;
  @media screen and (max-width: 1024px) {
    width: 97%;
    max-width: 550px;
    font-size: 17px;
  }
  span {
    color: #a4a3a8;
  }
  @each $name in down, up {
    .fa-angle-#{$name} {
      position: relative;
      top: 3px;
    }
  }
  #difficulty {
    position: relative;
    @media screen and (max-width: 667px) {
      strong {
        display: none;
      }
    }
    span {
      cursor: pointer;
    }
    .difficulty-selector {
      position: absolute;
      align-self: center;
      top: 2.3rem;
      left: 5.7rem;
      font-size: 17px;
      font-weight: 600;
      box-shadow: 8px 8px 9px#dbd7d7, -5px -4px 9px #dbd7d7;
      background-color: white;
      border-radius: 3px;
      color: #969595;
      opacity: 0;

      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      visibility: hidden;
      @media screen and (max-width: 1024px) {
        left: 0.3rem;
        top: 2rem;
      }
      p {
        padding-left: 10px;
        padding-top: 10px;
        padding-right: 50px;
        margin-top: -1px;
        margin-bottom: -1px;
        padding-bottom: 10px;
      }
      p:hover {
        background-color: #def;
        cursor: pointer;
      }
    }
    @keyframes appear {
      100% {
        opacity: 1;
        visibility: visible;
      }
    }
    @keyframes disappear {
      0% {
        opacity: 1;
        visibility: visible;
      }
      100% {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
