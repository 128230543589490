.game-controls {
  display: flex;

  flex-direction: column;
  width: 32%;
  margin-left: 50px;
  @media screen and (max-width: 1024px) {
    position: absolute;
    top: -0.25rem;
    right: 0.05rem;
  }
  @mixin game-control-style {
    cursor: pointer;
    border-right: 1px solid #bec6d4;
    border-bottom: 1px solid #bec6d4;
    font-size: 25px;
    font-weight: normal;
    color: #344861;

    &:hover {
      background-color: #def;
    }
  }
  .game-button {
    cursor: default;
    user-select: none;
    background-color: #4a90e2;
    border-radius: 7px;
    border: 0px;
    color: white;
    font-size: 20px;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
    font-weight: 600;
    z-index: 1;
    &:hover {
      opacity: 0.8;
    }
    @media screen and (max-width: 1024px) {
      position: absolute;
      display: block;
      background-color: white;
      color: #4a90e2;
      font-size: 17px;
      top: -0.25em;
      right: 0.6em;
      height: 0.2rem;
    }
  }
  .number-pad {
    border-left: 1px solid #bec6d4;
    border-top: 1px solid #bec6d4;
    display: grid;
    height: 70%;
    margin-top: 15px;
    grid-template-areas:
      "numpad1 numpad2 numpad3"
      "numpad4 numpad5 numpad6"
      "numpad7 numpad8 numpad9";
    $numpads: numpad1, numpad2, numpad3, numpad4, numpad5, numpad6, numpad7,
      numpad8, numpad9;
    @media screen and (max-width: 1024px) {
      display: none;
    }
    @each $numpad in $numpads {
      .#{$numpad} {
        grid-area: $numpad;
        @include game-control-style();
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 35px;
      }
    }
  }
  .controls-pad {
    display: grid;
    border-left: 1px solid #bec6d4;
    height: 30%;
    grid-template-areas:
      "hint erase"
      "visualize visualize";
    @media screen and (max-width: 1024px) {
      display: none;
    }
    $controlpads: visualize, hint, erase;

    @each $controlpad in $controlpads {
      .#{$controlpad} {
        grid-area: $controlpad;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include game-control-style();
        font-size: 23px;
      }
    }
  }
  .tooltip {
    position: absolute;
    box-shadow: 5px -3px 9px#dbd7d7, -5px 5px 9px #dbd7d7;
    background-color: white;
    align-self: center;
    top: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
    width: 17%;
    &::before {
      content: " ";
      position: absolute;
      top: -16%;

      border: 20px solid;
      border-color: transparent rgba(194, 194, 194, 0.1) white
        rgba(194, 194, 194, 0.1);
      @media screen and (max-width: 1024px) {
        right: 1.7em;
      }
    }
    @media screen and (max-width: 1024px) {
      top: 4rem;
      right: 0.3rem;
      width: 250px;
    }
    @keyframes appear {
      0% {
        opacity: 0;
        visibility: hidden;
      }
      100% {
        opacity: 1;
        visibility: visible;
      }
    }
    @keyframes disappear {
      0% {
        opacity: 1;
        visibility: visible;
      }
      100% {
        opacity: 0;
        visibility: hidden;
      }
    }
    #p {
      margin-top: -10px;
      display: flex;
      justify-content: center;
      padding-top: 15px;
      padding-bottom: 15px;
      width: 100%;
      &:hover {
        cursor: pointer;
        background-color: #def;
      }
    }
    #announce {
      text-align: center;
    }
  }
}
